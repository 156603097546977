import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import moment from "moment";
registerLocale('ru', ru);

export const parseDateTime = (dateString, utcToLocal) => {
    if (!dateString) return null;
    let reg = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    let dateArray = reg.exec(dateString);
    if (!dateArray) return null;
    const arrayForDate = [+dateArray[1], +dateArray[2] - 1, +dateArray[3], +dateArray[4], +dateArray[5]];
    let d = utcToLocal ? new Date(Date.UTC(...arrayForDate)) : new Date(...arrayForDate);
    return isNaN(d.getTime()) ? null : d;
};

export const formatDate = (date, utcToLocal) => {
    if (!date) return null;
    return (utcToLocal ? moment(date).utc() : moment(date)).format('YYYY-MM-DDTHH:mm:ss');
};

const DateTime = ({
                      value,
                      name,
                      onChange,
                      isDisabled,
                      popperPlacement = 'bottom-end',
                      placeholder,
                      min,
                      max,
                      utcToLocal = true, //по умолчанию считает, что на вход приходит время в UTC и его требуется отобразить в местном времени
                  }) => {
    const filterPassedTime = (time) => {
        const selectedDate = new Date(time);
        return (!max || max.getTime() >= selectedDate.getTime()) && (!min || min.getTime() <= selectedDate.getTime());
    };

    return (
        <DatePicker
            minDate={min}
            maxDate={max}
            filterTime={filterPassedTime}
            placeholderText={placeholder}
            locale={'ru'}
            disabled={isDisabled || false}
            isClearable={!(isDisabled || false)}
            selected={parseDateTime(value, utcToLocal)}
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeSelect
            timeIntervals={1}
            onChange={(date) => {
                onChange(date ? formatDate(date, utcToLocal) : null);
            }}
            popperPlacement={popperPlacement}
            type="text"
            customInput={
                <InputMask mask="99.99.9999 99:99"/>
            }
        />
    );
};
export default DateTime;
