import React, {useContext, useEffect, useRef, useState} from 'react';
import {ContextFooter, ContextGridSettings, ContextNavi, ContextUser} from "../../../services/context";
import {LinkTab} from "../../../layout/navbar/Navbar";
import UserPermissions from "../../../api/model/UserPermissions";
import {getAllAuctions} from "../../../api/waybills";
import WbGridTable from "./WbGridTable";
import Shield from "../../../layout/page/shield/Shield";
import AuctionsToolbar from "./AuctionsToolbar";
import AuctionRulesModal from "../../auctionRules";
import {UserRolesEnum} from "../../../api/usersRoles";

const AuctionsGrid = ({location, history}) => {
    const contextNavi = useContext(ContextNavi);
    const contextUser = useContext(ContextUser);
    const contextFooter = useContext(ContextFooter);
    const [rows, setRows] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const scrollContainer = useRef(null);
    const contextGridSettings = useContext(ContextGridSettings);
    const headers = contextGridSettings.auctionsColumns.filter(c => !c.hide).sort(function(a, b) {
        return a.order - b.order;
    });

    useEffect(() => {
        const permissions = contextUser.current.permissions;
        let items = [];
        if (permissions.includes(UserPermissions.WAYBILLS)) items.push(new LinkTab("Мои заявки", "/waybills"));
        if (permissions.includes(UserPermissions.AUCTIONS)) items.push(new LinkTab("Мои аукционы", "/waybills/auctions"));
        contextNavi.setItems(items);
    }, []);

    useEffect(() => {
        fetchData();
    }, [location.search]);

    useEffect(() => {
        contextFooter.setIndicator(
            () => <div className="table-footer">
                {selectedIds.length
                    ? `Выбрано: ${selectedIds.length} / `
                    : null}
                {rows.length} записей
            </div>
        );
        return (() => {
            contextFooter.setIndicator(null);
        });
    }, [selectedIds, rows]);

    async function fetchData() {
        setLoading(true);
        try {
            const result = await getAllAuctions(location.search);
            setRows(result);
        } finally {
            setLoading(false);
        }
    }

    const [modalRules, setModalRules] = useState(false);

    useEffect(() => {
        if(contextUser) {
            setModalRules(!contextUser.current.acceptanceOfTheAuctionRules && contextUser.current.role.id === UserRolesEnum.CLIENT && contextUser.current.permissions.includes(UserPermissions.AUCTIONS_EDIT));
        }
    }, [contextUser]);

    return (
        <Shield loading={loading}>
            <AuctionsToolbar
                location={location}
                selectedRows={rows.filter(r => selectedIds.includes(r.id))}
                setSelectedIds={setSelectedIds}
                refresh={fetchData}
                loading={setLoading}
            />
            <div className="table-wrapper-grid">
                <div className="table-scroll-grid" ref={scrollContainer}>
                    <WbGridTable
                        gridIdKey={'waybillId'}
                        gridName={'auctions'}
                        scrollComponent={scrollContainer}
                        setLoading={setLoading}
                        headers={headers}
                        rows={rows}
                        setRows={setRows}
                        selectedIds={selectedIds || []}
                        selectIds={setSelectedIds}
                        getRowsIds={() => rows.map(r => r.id)}
                        getRows={getAllAuctions}
                        stickyCols={['id']}
                    />
                </div>
            </div>
            <AuctionRulesModal open={modalRules} setOpen={setModalRules}/>
        </Shield>
    );
};

export default AuctionsGrid;