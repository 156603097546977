import O from "../../components/Option";
import { WaybillTypeOptions, WaybillCarTypeOptions } from "./Waybill";
import {getCompanyOptionsBySearch, getCompanySearchNamesOptions} from "../companies";
import {IncludeOptions, WeekDayOptions2} from "./Enums";
import {getConsigneesFromPoints, getPointOptionsFmidBySearch} from "../points";

export const DictionariesEnum = {
    NETWORKS: '1',
    REGIONS: '2',
    CITIES: '3',
    AVISATION: '4',
    CAR_TYPES: '5',
    ZONE: '6',
    INSURANCE_PERCENTAGE: '7',
    REASONS_FOR_BLOCKING: '8',
    SCHEDULES: '100',
    POINTS: '101',
};

export const BodyTypeEnum = {
    REF: 10,
    TENT: 20,
    ISOTERM: 30
};

export const BodyTypeOptions = [
    new O(BodyTypeEnum.REF, 'Реф'),
    new O(BodyTypeEnum.TENT, 'Тент'),
    new O(BodyTypeEnum.ISOTERM, 'Изотерма')
];

export const BooleanOptions = [
    new O('Да', 'Да'),
    new O('Нет', 'Нет')
];

export const DictionariesSettings = [
    {
        key: DictionariesEnum.NETWORKS,
        isNewType: false,
        urlName: 'networks',
        wordForModalLabel: 'сеть',
        headers: [
            {
                key: 'name',
                text: 'Сеть',
                sortable: true
            },
            {
                key: 'companyNames',
                text: 'Грузополучатели',
                sortable: true
            },
            {
                key: 'pointFMIDs',
                text: 'Название в системе FM',
                sortable: true
            }
        ]
    },
    {
        key: DictionariesEnum.REGIONS,
        isNewType: false,
        urlName: 'regions',
        wordForModalLabel: 'регион',
        headers: [
            {
                key: 'country',
                text: 'Страна',
                sortable: true
            },
            {
                key: 'name',
                text: 'Наименование региона',
                sortable: true
            },
            {
                key: 'postalCodes',
                text: 'Почтовые коды (индексы)',
                sortable: true
            },
            {
                key: 'timezone',
                text: 'Часовой пояс',
                sortable: true
            }
        ]
    },
    {
        key: DictionariesEnum.CITIES,
        isNewType: false,
        urlName: 'cities',
        wordForModalLabel: 'город',
        headers: [
            {
                key: 'name',
                text: 'Наименование города',
                sortable: true
            },
            {
                key: 'region',
                text: 'Регион',
                sortable: true
            }
        ]
    },
    {
        key: DictionariesEnum.AVISATION,
        isNewType: false,
        urlName: 'avisation',
        wordForModalLabel: 'авизацию',
        headers: [
            {
                key: 'name',
                text: 'Наименование группы',
                sortable: true
            },
            {
                key: 'clientNames',
                text: 'Клиенты, входящие в группу',
                sortable: true
            }
        ]
    },
    {
        key: DictionariesEnum.CAR_TYPES,
        isNewType: true,
        urlName: 'carType',
        headers: [
            {
                key: 'name',
                text: 'Наименование',
                sortable: true,
                fieldType: 'text',
                isRequired: true
            },
            {
                key: 'tonnage',
                text: 'Тоннаж',
                sortable: true,
                fieldType: 'text',
                filterType: 'checkboxes',
                isRequired: true,
                separator: '|'
            },
            {
                key: 'bodyType',
                text: 'Тип кузова',
                sortable: true,
                fieldType: 'enum',
                items: BodyTypeOptions
            },
            {
                key: 'planPalletCount',
                text: 'Плановое количество паллет',
                sortable: true,
                fieldType: 'number',
                isRequired: true
            },
            {
                key: 'minimalTemperature',
                text: 'Температурный интервал: минимальная температура',
                sortable: true,
                fieldType: 'number',
                isInteger: true
            },
            {
                key: 'maximalTemperature',
                text: 'Температурный интервал: максимальная температура',
                sortable: true,
                fieldType: 'number',
                isInteger: true
            },
            {
                key: 'bodyCapacity',
                text: 'Вместимость, м3',
                sortable: true,
                fieldType: 'number'
            },
            {
                key: 'trainCarType',
                text: 'Тип вагона',
                sortable: true,
                fieldType: 'enum',
                items: WaybillCarTypeOptions
            },
            {
                key: 'fmKey',
                text: 'Ключ FM',
                sortable: true,
                fieldType: 'text',
                maxLength: 4,
                isRequired: true
            },
            {
                key: 'loadingUnloadingStandard',
                text: 'Норматив по загрузке/выгрузке, ч',
                sortable: true,
                fieldType: 'number'
            },
            {
                key: 'allClientAccess',
                text: 'Доступность для всех клиентов',
                sortable: true,
                fieldType: 'bool',
                isRequired: true,
                default: 'Нет'
            },
            {
                key: 'exceptedClientFMIDs',
                text: 'Исключение',
                sortable: true,
                fieldType: 'multiselect',
                filterType: 'checkboxes',
                items: getCompanySearchNamesOptions
            },
            {
                key: 'waybillTypes',
                text: 'Тип заявки',
                sortable: true,
                fieldType: 'enums',
                items: WaybillTypeOptions
            },
            {
                key: 'isAuctionAccess',
                text: 'Доступность для аукционов',
                sortable: true,
                fieldType: 'bool',
                isRequired: true,
                default: 'Нет'
            },
            {
                key: 'isActive',
                text: 'Активен',
                sortable: true,
                fieldType: 'bool',
                isRequired: true,
                default: 'Да'
            }
        ]
    },
    {
        key: DictionariesEnum.ZONE,
        isNewType: true,
        urlName: 'zone',
        headers: [
            {
                key: 'country',
                text: 'Страна',
                sortable: true,
                fieldType: 'text',
                filterType: 'checkboxes',
                isRequired: true
            },
            {
                key: 'postalCodeStart',
                text: 'Диапазон индексов (от)',
                sortable: true,
                fieldType: 'number',
                filterCombineKey: 'postalCode',
                filterType: 'number',
                isInteger: true,
                min: 0,
                max: 999999,
                isRequired: true
            },
            {
                key: 'postalCodeEnd',
                text: 'Диапазон индексов (до)',
                sortable: true,
                fieldType: 'number',
                filterCombineKey: 'postalCode',
                filterType: 'number',
                isInteger: true,
                min: 0,
                max: 999999,
                isRequired: true
            },
            {
                key: 'tariffZone',
                text: 'Тарифная зона',
                sortable: true,
                fieldType: 'text',
                filterType: 'checkboxes',
                isRequired: true
            },
            {
                key: 'name',
                text: 'Наименование зоны',
                sortable: true,
                fieldType: 'text',
                filterType: 'checkboxes',
                isRequired: true
            },
        ]
    },
    {
        key: DictionariesEnum.INSURANCE_PERCENTAGE,
        isNewType: true,
        urlName: 'insurancePercentage',
        headers: [
            {
                key: 'clientFMID',
                text: 'Клиент',
                sortable: true,
                fieldType: 'select',
                filterType: 'checkboxes',
                items: getCompanyOptionsBySearch,
                placeholder: 'Все клиенты'
            },
            {
                key: 'consignee',
                text: 'Грузополучатель',
                sortable: true,
                fieldType: 'select',
                filterType: 'text',
                items: getConsigneesFromPoints,
                isRequired: true
            },
            {
                key: 'cargoProtection',
                text: 'Защита грузов, %',
                sortable: true,
                fieldType: 'number',
                filterType: 'number',
                min: 0.01,
                max: 99.99,
                decimalPlaces: 2,
                requiredThisOr: ['timingProtection']
            },
            {
                key: 'timingProtection',
                text: 'Защита сроков, %',
                sortable: true,
                fieldType: 'number',
                filterType: 'number',
                min: 0.01,
                max: 99.99,
                decimalPlaces: 2,
                requiredThisOr: ['cargoProtection']
            },
        ]
    },
    {
        key: DictionariesEnum.REASONS_FOR_BLOCKING,
        isNewType: true,
        urlName: 'blockReasons',
        headers: [
            {
                key: 'name',
                text: 'Причина блокировки',
                sortable: true,
                fieldType: 'text',
                filterType: 'text',
                isRequired: true
            }
        ]
    },
    {
        key: DictionariesEnum.SCHEDULES,
        fullCopiesAreProhibited: true,
        isNewType: true,
        urlName: 'scheduleStandardForm',
        customText: {
            importBtnName: 'Импорт графиков',
            addBtnName: 'Добавить график',
            copyBtnName: 'Скопировать графики',
            copyInfo: 'Для копирования графиков, пожалуйста, укажите клиента или наименование формы.',
            name: 'график',
            deletedText: 'График удален',
            exampleBtn: "Скачать пример для импорта графиков",
        },
        exampleFile: 'Пример экспорта и импорта графиков формы импорта.xlsx',
        defaultsDataForAdd: {
            isDefault: undefined
        },
        idParamName: 'waybillImportFormId',
        headers: [
            {
                key: 'unloadingPointId',
                text: 'Searchname адреса разгрузки',
                sortable: true,
                fieldType: 'withError',
                errorFrom: 'isAddressNotBelongsClient',
                filterType: 'text',
                isRequired: true,
                items: getPointOptionsFmidBySearch,
            },
            {
                key: 'uploadingDay',
                text: 'День загрузки',
                sortable: true,
                fieldType: 'enum',
                filterType: 'enum',
                items: WeekDayOptions2,
                isRequired: true
            },
            {
                key: 'transit',
                text: 'Транзит',
                sortable: true,
                fieldType: 'number',
                filterType: 'number',
                isInteger: true,
                isRequired: true,
                min: 1,
                max: 100,
            },
            {
                key: 'unloadingDay',
                text: 'День разгрузки',
                sortable: true,
                fieldType: 'enum',
                filterType: 'enum',
                items: WeekDayOptions2,
                isRequired: true
            },
            {
                key: 'unloadingFrom',
                text: 'Время разгрузки С',
                sortable: true,
                fieldType: 'time',
                filterType: 'time',
                isRequired: true
            },
            {
                key: 'unloadingTo',
                text: 'Время разгрузки По',
                sortable: true,
                fieldType: 'time',
                filterType: 'time',
                isRequired: true
            },
            {
                key: 'isPlusOneDay',
                text: 'Учитывать +1 день',
                sortable: true,
                fieldType: 'checkbox',
                filterType: 'enum',
                items: IncludeOptions,
            },
        ]
    },
    {
        key: DictionariesEnum.POINTS,
        fullCopiesAreProhibited: true,
        isNewType: true,
        urlName: 'pointStandardForm',
        customText: {
            importBtnName: 'Импорт адресов',
            addBtnName: 'Добавить адрес',
            copyBtnName: 'Скопировать адреса',
            copyInfo: 'Для копирования адресов, пожалуйста, укажите наименование формы, с которой необходимо произвести копирование.',
            name: 'адрес',
            deletedText: 'Адрес удален',
            addModalTitle: 'Добавить адрес в справочник "Адреса"',
            saveTitle: 'Добавить адрес в справочник',
            exampleBtn: "Скачать пример для импорта адресов",
            addInfo: "Для добавления адреса в справочник, пожалуйста, укажите Searchname адреса и код адреса/адрес грузополучателя."
        },
        exampleFile: 'Пример экспорта и импорта адресов формы импорта.xlsx',
        idParamName: 'waybillImportFormId',
        headers: [
            {
                key: 'pointFMID',
                text: 'Searchname адреса',
                sortable: true,
                fieldType: 'select',
                filterType: 'text',
                isRequired: true,
                items: getPointOptionsFmidBySearch,
            },
            {
                key: 'code',
                text: 'Код адреса',
                sortable: true,
                fieldType: 'text',
                filterType: 'text',
                requiredThisOr: ['consigneeAddress']
            },
            {
                key: 'consignee',
                text: 'Грузополучатель',
                sortable: true,
                fieldType: 'text',
                filterType: 'text',
            },
            {
                key: 'consigneeAddress',
                text: 'Адрес грузополучателя',
                sortable: true,
                fieldType: 'text',
                filterType: 'text',
                requiredThisOr: ['code']
            },
        ]
    }
];