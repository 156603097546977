import debounce from 'awesome-debounce-promise';
import moment from 'moment';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Confirm, Divider, Dropdown, Grid, GridColumn, Icon, Segment} from 'semantic-ui-react';
import {getCompanyById, getCompanyCargoRestrictions, getCompanyFieldConfigs,} from '../../../api/companies';
import {PickupTypeEnum, WaybillFieldEnum} from '../../../api/model/Enums';
import UserPermissions from '../../../api/model/UserPermissions';
import Waybill, {
    AuctionCancelledStatuses,
    AuctionEditStatuses,
    AuctionStatusEnum,
    WaybillStatusAvizationEnum,
    WaybillStatusEnum,
    WaybillTypeEnum,
    WaybillTypesWithRoutes,
} from '../../../api/model/Waybill';
import {getMinCapacity} from '../../../api/tonnage';
import {getUserCompanyOptions} from '../../../api/users';
import {
    auctionCancel,
    auctionCompleteWithWinner,
    auctionUpdate,
    createRoute,
    createWaybill,
    getAuctionCargoAndTermProtectionCosts,
    getAwisationIds,
    getHasToMirror,
    getWaybillById,
    getWaybillsInVisit,
    updateWaybill,
    updateWaybillClient,
    waybillDelete,
    waybillResend,
    waybillValidationStatusUpdate,
    wbIsEdit
} from '../../../api/waybills';
import {getWaybillStatusTexts} from '../../../api/waybillsUtils';
import O from '../../../components/Option';
import T from '../../../components/Translate';
import {LinkInfo} from '../../../layout/navbar/Navbar';
import Spinner from '../../../layout/page/Spinner';
import {getToken} from '../../../services/auth';
import {ContextFieldConfigs, ContextFooter, ContextNavi, ContextUser, ContextWb,} from '../../../services/context';
import {toast} from '../../../services/toast';
import {showField} from '../../admin/companies/companyTabs/companyTabFieldConfig/companyTabUtils';
import WaybillStatusOrder from '../common/WaybillStatusOrder';
import WaybillStatusShipping from '../common/WaybillStatusShipping';
import WbProcessing from '../common/WbProcessing';
import './WbEdit.css';
import WbEditMain from './WbEditMain';
import WbEditPoints from './WbEditPoints';
import {PointServiceEnum} from "../../../api/model/PointService";
import getIndexWbPoints from "../../../services/getIndexWbPoints";
import WbAvizModal from '../wbGrid/WbAvizModal';
import {LoadCapacityOptions} from '../../../api/model/Point';
import WbEditAvisTimeslot from "../wbViewAvisation/WbEditAvisTimeslot";
import {Prompt} from "react-router";
import {onBeforeUnloadOff, onBeforeUnloadOn, replaceObjectById, swapObjectsById} from "../../../services/utils";
import AuctionBlock from "./AuctionBlock";
import AuctionStartModal from "./auctionStartModal";
import HistoryModal from "./historyModal";
import {checkTime} from "../../../services/normalizeTime";
import CompleteWithWinnerBtn from "../common/completeWithWinnerBtn";

const updateDataWithTimeout = debounce((dtoUpdated, fetchData) => updateWaybill(dtoUpdated, fetchData), 1000);
const updateAuctionDataWithTimeout = debounce((dtoUpdated, fetchData) => auctionUpdate(dtoUpdated, fetchData), 1000);
const getAuctionCargoAndTermProtectionCostsWithTimeout = debounce((dtoUpdated, fetchData) => getAuctionCargoAndTermProtectionCosts(dtoUpdated, fetchData), 1000);

export const auctionValidation = (dto, isExtension) => {
    const empty = field => `Поле "${field}" обязательно к заполнению`;

    let errors = [];

    if (!(dto.auction || {}).auctionType) errors.push({key: 'auctionType', txt: empty('Тип аукциона'.t)});
    if (!(dto.auction || {}).initialOffer) errors.push({key: 'initialOffer', txt: empty('Начальная стоимость, без НДС'.t)});
    if (dto.auction && dto.auction.initialOffer && dto.auction.initialOffer < 1000) errors.push({key: 'initialOffer', txt: 'Начальная ставка не может быть меньше 1000'});
    if (dto.auction && dto.auction.auctionStart && [AuctionStatusEnum.DRAFT, AuctionStatusEnum.CREATED].includes((dto.auction || {}).auctionStatus)) {
        const equalDate1 = new Date();
        const equalDate2 = new Date((dto.auction || {}).auctionStart + 'Z');
        if (equalDate1.getTime() > equalDate2.getTime()) errors.push({
            key: 'auctionStart',
            txt: "Дата и время начала торгов не может быть ранее текущей даты и времени"
        });
    }
    if (dto.auction && dto.auction.auctionEnd && isExtension) {
        const equalDate1 = new Date();
        const equalDate2 = new Date((dto.auction || {}).auctionEnd + 'Z');
        if (equalDate1.getTime() > equalDate2.getTime()) errors.push({
            key: 'auctionEnd',
            txt: "Дата и время окончания торгов не может быть ранее текущей даты и времени"
        });
    }
    if (dto.auction && (dto.auction.auctionStart || dto.auction.auctionEnd)) {
        const equalDate1 = new Date(dto.auction.auctionStart);
        const equalDate2 = new Date(dto.auction.auctionEnd);
        if (equalDate1.getTime() > equalDate2.getTime()) errors.push({
            key: 'auctionEnd',
            txt: "Время окончания торгов не может быть ранее времени начала торгов"
        });

        let loadingDate = dto.pointsLoading && (dto.pointsLoading[0] || {}).arrivalDatePlan ? moment(dto.pointsLoading[0].arrivalDatePlan).toDate() : null;
        if (loadingDate) {
            const loadingTimeFrom = ((dto.pointsLoading[0] || {}).arrivalTimeslotPlan || {}).from;
            if (loadingTimeFrom) {
                const time = loadingTimeFrom.split(':') || [];
                if (time) {
                    time[0] && loadingDate.setHours(time[0]);
                    time[1] && loadingDate.setMinutes(time[1]);
                }
            }

            if (dto.auction.auctionStart) {
                const equalDate = new Date(dto.auction.auctionStart);
                if (loadingDate.getTime() <= equalDate.getTime()) errors.push({
                    key: 'auctionStart',
                    txt: "Дата и время начала торгов не может быть позже даты загрузки"
                });
            }

            if (dto.auction.auctionEnd) {
                const equalDate = new Date(dto.auction.auctionEnd);
                if (loadingDate.getTime() <= equalDate.getTime()) errors.push({
                    key: 'auctionEnd',
                    txt: "Дата и время окончания торгов не может быть позже даты загрузки"
                });
            }
        }
    }
    if (dto.cargoCost > 30000000) errors.push({key: null, txt: 'Заявка не может быть размещена на аукционе, так как стоимость груза превышает лимит. Для решения вопроса свяжитесь со специалистом CS'});
    return errors.length ? errors : null;
};
export default function WbEditor({match, history, location, isClient, isAuction, isPoolingEdit}) {
    const contextNavi = useContext(ContextNavi);
    const contextUser = useContext(ContextUser);
    const contextFooter = useContext(ContextFooter);
    const canEdit = contextUser.current.permissions.includes(UserPermissions.WAYBILL_EDIT);
    const avisationPermitted = !contextUser.current.permissions.includes(UserPermissions.DISALLOW_AVISATION);

    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(true);
    const [dto, setDto] = useState({});
    const [routes, setRoutes] = useState([]);
    const [dtoError, setDtoError] = useState(null);
    const [cargoRestrictions, setCargoRestrictions] = useState([]);
    const [hasUnsaved, setHasUnsaved] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [fieldConfigs, setFieldConfigs] = useState([]);
    const [isOpenAvizModal, setIsOpenAvizModal] = useState(false);
    const [newLocation, setNewLocation] = useState(null);
    const [editTimeslotData, setEditTimeslotData] = useState(null);
    const [waybillsWithVisit, setWaybillsWithVisit] = useState([]);
    const lastSavePromise = useRef(null);
    let wbId = match.params.id;
    const isDraft = dto && dto.status === WaybillStatusEnum.DRAFT;
    const isAuctionDraft = dto && (dto.auction || {}).auctionStatus === AuctionStatusEnum.DRAFT;
    const isWbHubMoscow =
        dto.pointsLoading && dto.pointsLoading.some(pl => pl.crossDockWithActivity || pl.avisationByEmail);
    const canChangePickupType = canEdit && !isDraft && isWbHubMoscow && !dto.pickupType;

    const isPoolingDraft = dto.status === WaybillStatusEnum.DRAFT && dto.isPooling;
    const auctionView = isAuction && dto.auction;

    const removeIdsFromPoints = (points) => points.map(point => {
        const deleteIds = (set) => {
            const copy = { ...set };
            delete copy.id;
            return copy;
        };

        const deleteIdsFromArray = (arr) => {
            return arr.map(s => deleteIds(s));
        };

        return {
            ...point,
            loadUnitSets: deleteIdsFromArray(point.loadUnitSets),
            loadUnitsToOrderMappings: deleteIdsFromArray(point.loadUnitsToOrderMappings),
            services: deleteIdsFromArray(point.services),
        };
    });

    function removeIds(dto) {
        return {
            ...dto,
            pointsLoading: removeIdsFromPoints(dto.pointsLoading),
            pointsUnloading: removeIdsFromPoints(dto.pointsUnloading)
        };
    }

    const setIsValid = (dto, newRoutes) => {
        const verror = getValidationError([dto, ...newRoutes], fieldConfigs);
        setDtoError(verror);
        const dtoUpdated = {
            ...dto,
            isValid: !verror
        };
        setDto(dtoUpdated);

        const routes = (newRoutes || []).map(r => ({...r, isValid: !verror}));

        newRoutes.length && setRoutes(routes);

        return [dtoUpdated, ...routes];
    };

    async function save(pickupTypeInner, isResend) {
        try {
            if (isClient) {
                await updateWaybillClient(pickupTypeInner ? {
                    ...dto,
                    pickupType: pickupTypeInner,
                } : dto, isResend).catch(err => {
                    fetchData();
                    throw err;
                });
            } else {
                const dtos = setIsValid(removeIds(dto), (routes || []).map(r => removeIds(r)));
                const data = await updateWaybill(dtos).catch(err => {
                    fetchData();
                    throw err;
                });
                await viewModal(data);
            }
        } finally {
            setHasUnsaved(false);
        }
    }

    async function fetchData() {
        const currentUserToken = getToken();

        let companyOptions = [];

        currentUserToken && (companyOptions = await getUserCompanyOptions(isAuction));

        let wb;
        let routes = [];
        try {
            wb = await getWaybillById(wbId, true);
            if (wb.length > 0) {
                routes = wb.slice(1);
                setRoutes(routes);
            }
            wb = getIndexWbPoints(wb[0]);
        } catch (e) {
            toast.error('Не удалось загрузить заявку');
            return;
        }
        if (!wb.clientId) {
            wb.clientId =
                (companyOptions && companyOptions.length) === 1 ? companyOptions[0].key : null;
        } else {
            if (!companyOptions || !companyOptions.find(co => co.key === wb.clientId)) {
                const cmp = await getCompanyById(wb.clientId);
                companyOptions = [new O(cmp.fmid, cmp.name)].concat(companyOptions).sort((a, b) => {
                    if (a.key === wb.clientId) {
                        return -1;
                    }
                    return 1;
                });
            }
        }

        wb.__optionsCompany = companyOptions;
        wb.__canEdit = wb.status !== WaybillStatusEnum.DRAFT && canEdit;

        const companyId = wb && wb.clientId;
        const configs = await getCompanyFieldConfigs(companyId);
        // wb can be invalid by some error - need to reinvalidate
        let error = getValidationError([wb, ...routes], configs);
        if (error) {
            setDtoError(error);
            wb.isValid = false;
            routes.length && setRoutes(routes.map(r => ({...r, isValid: false})));
        } else {
            if (!wb.isValid) {
                wb.isValid = true;
                routes.length && setRoutes(routes.map(r => ({...r, isValid: true})));
                waybillValidationStatusUpdate(wb.id, true);
            }
        }
        delete wb['providerName'];
        setDto(wb);

        setLoading(false);

        if (contextNavi.setItems) {
            const wbLinkInfo = isAuction && (wb.auction || {}).id
                ? new LinkInfo(`${'Аукцион'.t} ${wb.auction.id}`, '/')
                : (wb.__canEdit
                    ? new LinkInfo(`${'Заявка'.t} ${wb.fmid || wb.id}`, '/')
                    : new LinkInfo('Черновик'.t, '/'));

            contextNavi.setItems([new LinkInfo(isAuction ? 'Мои аукционы'.t : 'Мои заявки'.t, `/waybills${isAuction ? '/auctions' : ''}`), wbLinkInfo]);
        }

        return wb;
    }

    async function goToNew() {
        let companyOptions = await getUserCompanyOptions(isAuction);
        await updateWaybill({ ...dto, ...routes });

        const wb = new Waybill();

        wb.clientId = companyOptions && companyOptions.length === 1 ? companyOptions[0].key : null;

        wb.__optionsCompany = companyOptions;

        const newDto = await createWaybill();

        history.push(`/waybills/${newDto.id}/edit`);

        match.params.id = newDto.id;

        wbId = newDto.id; //await fetchData();
    }

    function positionsCountMatch(wb) {
        let usedIndexes = [];

        const loadingSetsAll = (wb.pointsLoading || []).reduce(
            (res, pl) => res.concat(pl.loadUnitSets || []),
            [],
        );

        const totalUnitCount = loadingSetsAll.reduce((res, lus) => res + lus.loadUnitsCount, 0);

        const positions = (wb.pointsUnloading || []).reduce(
            (arr, pu) => arr.concat(pu.loadUnitsToOrderMappings || []),
            [],
        );

        positions.forEach(p => {
            let posStart = p.loadUnitPositionStart || 0;
            let posEnd = p.loadUnitPositionEnd || posStart;

            if (posStart) {
                for (let index = posStart; index <= posEnd; index++) {
                    if (!usedIndexes.includes(index)) {
                        usedIndexes.push(index);
                    }
                }
            }
        });

        return usedIndexes.length === totalUnitCount;
    }

    const cargoCostIsRequired = (dto) => {
        const point = dto.pointsLoading && dto.pointsUnloading && [...dto.pointsLoading, ...dto.pointsUnloading].find(point=>
            point.services.find(service=>
                service.key === PointServiceEnum.CARGO_PROTECTION || service.key === PointServiceEnum.TERM_PROTECTION));
        return !!point;
    };

    function getValidationError(dtos, configs, isRoute) {

        const dto = Array.isArray(dtos) ? dtos[0] : dtos;

        const routes = Array.isArray(dtos) ? dtos.slice(1) : null;

        if (!WaybillTypesWithRoutes.includes(dto.shippingType) && routes && routes.length > 1) {
            return routes[1].pointsUnloading.length
                ? "Количество точек на выгрузке не должно быть больше 1"
                : "Количество точек на загрузке не должно быть больше 1";
        }

        const empty = field => `${'Не заполнено поле'.t} "${field}"`;
        const carTypeFieldIsView = dto._carTypeFieldIsView;
        const isFTL = dto.shippingType === WaybillTypeEnum.FTL;
        const isRailLTL = dto.shippingType === WaybillTypeEnum.RailLTL;
        const isRail = dto.shippingType === WaybillTypeEnum.Rail;

        if (routes && routes.length && !routes.every(route => (route.pointsLoading || []).length && (route.pointsUnloading || []).length)) {
            return "Для одного из маршрутов отсутствует информация о точке загрузки/выгрузки";
        }

        if (isAuction) {
            const errors = auctionValidation(dto);
            if (errors) {
                const errorsWithKeys = errors.filter(e => e.key);
                if (errorsWithKeys.length) return errorsWithKeys[0].txt;
            }
        }

        if (!dto.clientId) return empty('Клиент'.t);

        if (!dto.cargoType && showField(WaybillFieldEnum.CargoType, dto, configs)) {
            if (!routes || !routes.length || !routes.every(r => r.cargoType)) {
                // условие для варианта, когда указан тип во всех маршрутах, но не в О-заявке.
                // требуется считать такой вариант валидным, при сохранении заполняется из С-заявок
                return empty('Тип груза'.t);
            }
        }

        if (
            !dto.vehicleCapacity &&
            dto.shippingType === WaybillTypeEnum.FTL &&
            showField(WaybillFieldEnum.VehicleCapacity, dto, configs)
            && !carTypeFieldIsView
        )
            return empty('Грузоподъемность'.t);

        if (
            ((!isRail && !isFTL && !isRailLTL) || (isFTL && !carTypeFieldIsView)) &&
            !dto.shippingTemperatureCondition &&
            showField(WaybillFieldEnum.ShippingTempCondition, dto, configs) && !isRoute
        )
            return empty('Температурный режим'.t);

        if (
            (!dto.carTypeFMKey && ((isFTL && carTypeFieldIsView) || isRail || isRailLTL)) &&
            showField(WaybillFieldEnum.CarType, dto, configs)
        )
            return empty('Тип ТС'.t);

        if (dto.shippingType === WaybillTypeEnum.FTL && dto.shippingTemperatureCondition === 60 && (!dto.temperatureCondition || !dto.temperatureCondition.from || ! dto.temperatureCondition.to)) {
            if (!isRoute) // не нужно проверять в маршрутах, бэк подставит из О-заявки
                return empty("Температурный режим клиента".t);
        }

        if (
            !(
                (dto.pointsLoading && dto.pointsLoading.length) &&
                (dto.pointsUnloading && dto.pointsUnloading.length)
            )
        )
            return 'Отсутствуют точка(и) загрузки/выгрузки'.t;

        if (!(dto.pointsLoading.every(pl => !pl.isLocked) && dto.pointsUnloading.every(pu => !pu.isLocked)))
            return 'Выбранный адрес заблокирован'.t;

        if (
            !dto.pointsLoading.every(
                pl => pl.arrivalDatePlan && pl.arrivalTimeslotPlan && pl.arrivalTimeslotPlan.from,
            )
        )
            return 'Отсутствует информация о дате/времени загрузки'.t;

        if (
            !dto.pointsLoading.every(
                pl =>
                    pl.arrivalTimeslotPlan &&
                    checkTime(pl.arrivalTimeslotPlan.from) &&
                    checkTime(pl.arrivalTimeslotPlan.to),
            )
        )
            return 'Некорректный формат времени загрузки'.t;

        if (!dto.pointsLoading.every(pl => pl.arrivalTimeslotPlan && pl.arrivalTimeslotPlan.from !== '00:00' && pl.arrivalTimeslotPlan.to !== '00:00'))
            return "Время в загрузке не должно быть 00:00".t;

        if (!dto.pointsLoading.every(pl => !dto.pointsUnloading.every(pu => pl.arrivalTimeslotPlan && pu.arrivalTimeslotPlan && pl.arrivalDatePlan === pu.arrivalDatePlan && pl.arrivalTimeslotPlan.from === pu.arrivalTimeslotPlan.from)))
            return 'Время в загрузке не должно быть равно времени в выгрузке'.t;

        if (!dto.pointsLoading.every(pl => pl.loadUnitSets && pl.loadUnitSets.length))
            return 'Отсутствует информация о грузовых единицах в точке(ах) загрузки'.t;

        if (
            dto.pointsLoading.filter(
                pl =>
                    pl.services.length > 0 &&
                    pl.services.filter(
                        i =>
                            (i.key !== PointServiceEnum.TEMPREC && !i.value) ||
                            (i.key !== PointServiceEnum.TEMPREC && i.value === null),
                    ).length,
            ).length
        )
            return 'Не указано значение по доп. услугам'.t;

        if (
            dto.pointsUnloading.filter(
                pl =>
                    pl.services.length > 0 &&
                    pl.services.filter(
                        i =>
                            ((i.key !== PointServiceEnum.TEMPREC && !i.value) ||
                                (i.key !== PointServiceEnum.TEMPREC && i.value === null)) &&
                            (i.key !== PointServiceEnum.TERM_PROTECTION) &&
                            (i.key !== PointServiceEnum.CARGO_PROTECTION)
                            && (i.key !== PointServiceEnum.PAID_ENTRY)
                    ).length,
            ).length
        )
            return 'Не указано значение по доп. услугам'.t;

        if (
            !dto.pointsUnloading.every(
                pl => pl.arrivalDatePlan && pl.arrivalTimeslotPlan && pl.arrivalTimeslotPlan.from,
            )
        )
            return 'Отсутствует информация о дате/времени выгрузки'.t;

        if (
            !dto.pointsUnloading.every(
                pl =>
                    pl.arrivalTimeslotPlan &&
                    checkTime(pl.arrivalTimeslotPlan.from) &&
                    checkTime(pl.arrivalTimeslotPlan.to),
            )
        )
            return 'Некорректный формат времени разгрузки'.t;

        if (!dto.pointsUnloading.every(pl => pl.arrivalTimeslotPlan && pl.arrivalTimeslotPlan.from !== '00:00' && pl.arrivalTimeslotPlan.to !== '00:00'))
            return "Время в выгрузке не должно быть 00:00".t;

        if (!dto.pointsUnloading.every(pu => pu.loadUnitsToOrderMappings && pu.loadUnitsToOrderMappings.length)) {
            return "Отсутствует информация о номерах заказов в точке(ах) выгрузки".t;
        }
        if (
            !dto.pointsUnloading.every(
                pu => pu.loadUnitsToOrderMappings && pu.loadUnitsToOrderMappings.length,
            )
        ) {
            return 'Отсутствует информация о номерах заказов в точке(ах) выгрузки'.t;
        }

        if (dto.shippingType === WaybillTypeEnum.FTL) {
            const currentCapacity = dto.vehicleCapacity || 0;

            const minRequiredCapacity = getMinCapacity(dto, cargoRestrictions);

            if (currentCapacity < minRequiredCapacity)
                return 'Грузоподъемность транспортного средства не подходит под весовые показатели груза'
                    .t;
        }

        if (!positionsCountMatch(dto))
            return 'Заданные позиции на загрузке не соответствуют позициям на выгрузке'.t;

        if (!isValidDataAndTime(dto))
            return 'Дата/время на загрузке/выгрузке заполнены некорректно'.t;

        if (dto.pointsLoading && dto.pointsLoading.length > 1 && [WaybillTypeEnum.LTL, WaybillTypeEnum.PlanReturn].includes(dto.shippingType)) {
            return 'Количество точек на загрузке не должно быть больше 1'
        }

        if (dto.pointsUnloading && dto.pointsUnloading.length > 1 && [WaybillTypeEnum.LTL, WaybillTypeEnum.PlanReturn].includes(dto.shippingType)) {
            return 'Количество точек на выгрузке не должно быть больше 1'
        }

        if (cargoCostIsRequired(dto)) {
            if (!dto.cargoCost) return `Не заполнено поле "Стоимость груза без НДС"`;
            if (dto.cargoCost <= 50) return `Стоимость груза без НДС должна превышать 50`;
        }

        for (let i = 0; i < (routes || []).length; i++) {
            const err = getValidationError(getIndexWbPoints(routes[i]), configs, true);
            if (err) return err;
        }

        return null;
    }

    const auctionAllValidation = (dto) => {
        let error = getValidationError([dto, ...routes], fieldConfigs);
        if (error) {
            setDtoError(error);
            const errors = auctionValidation(dto);
            if (errors) {
                toast.error(errors.map(e => e.txt).join(';\n'));
                setErrorsAuction(errors.map(e => e.key));
            }
            else toast.error(error);
            return false;
        }
        return true;
    };

    const auctionSave = (f, c) => {
        if (!auctionAllValidation(dto)) return false;
        else {
            setLoading(true);
            auctionUpdate(removeIds({...dto, isSave: true}))
                .then(() => {
                    if (f) f();
                    else {
                        fetchData().then(r => {
                            c && c(r);
                        });
                    }
                    setHasUnsaved(false);
                    setLoading(false);
                })
                .catch(e => {
                    console.log(e);
                    setLoading(false);
                });
        }
    };

    const cancel = () => {
        setLoading(true);
        auctionCancel(dto.auction.id)
            .then(() => {
                fetchData();
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const completeWithWinner = () => {
        setLoading(true);
        auctionCompleteWithWinner(dto.auction.id)
            .then(() => {
                fetchData();
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    function update(prop) {
        let dtoUpdated = { ...dto, ...prop };
        delete dtoUpdated['providerName'];
        setDto(prevState => {
            return dtoUpdated;
        });
    }

    async function viewModal(data) {
        if (data.isAvisationCanceled) {
            if (!data.reservationWaybillIds || !data.reservationWaybillIds.length)
            {
                toast.success(`Бронь № ${data.canceledReservation.visitNumber} отменена`);
            }
            else {
                const res = await getWaybillsInVisit(data.reservationWaybillIds);
                setWaybillsWithVisit(res);
                setIsOpenAvizModal(true);
            }
        }
        if (data.isUpdated) {
            setEditTimeslotData(data);
        }
    }

    async function sendToFm({pickupTypeInner}) {
        try {
            setLoading(true);
            try {
                if (location && location.state && location.state.isEditTorg12) {
                    await save(pickupTypeInner, true);
                } else {
                    await save(pickupTypeInner);
                    await waybillResend(dto.id);
                    if (dto.reservationId && dto.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES && avisationPermitted) {
                        const param = await getAwisationIds(dto.reservationId);
                        if (param && param.length > 0) {
                            const res = await getWaybillsInVisit(param);
                            setWaybillsWithVisit(res);
                            setIsOpenAvizModal(true);
                        }
                    } else {
                        history.push(`/waybills`);
                    }
                }
            } finally {
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
        }

    }

    function setLoadPonitMax(wb, value) {
        const point = wb.pointsLoading[0];
        const set = point.loadUnitSets[0];

        return {
            ...wb,
            pointsLoading: [
                {
                    ...point,
                    loadUnitSets: [
                        {
                            ...set,
                            loadUnitsCount: value,
                        },
                    ],
                },
            ],
        };
    }

    function getLoadPointMax(wb) {
        const point = wb.pointsLoading[0];
        const set = point.loadUnitSets[0];
        return set.loadUnitsCount;
    }

    function setUnloadPonitMax(wb, value) {
        const point = wb.pointsUnloading[0];
        const set = point.loadUnitsToOrderMappings[0];

        return {
            ...wb,
            pointsUnloading: [
                {
                    ...point,
                    loadUnitsToOrderMappings: [
                        {
                            ...set,
                            loadUnitPositionEnd: value,
                        },
                    ],
                },
            ],
        };
    }

    function getUnloadPonitMax(wb) {
        const point = wb.pointsUnloading[0];
        const set = point.loadUnitsToOrderMappings[0];
        return set.loadUnitPositionEnd;
    }

    function hasNewPositions(dto1, dto2) {
        if (
            dto1.pointsLoading.length !== dto2.pointsLoading.length ||
            dto1.pointsUnloading.length !== dto2.pointsUnloading.length
        ) {
            return false;
        }
        const dto1Load = dto1.pointsLoading[0];
        const dto2Load = dto2.pointsLoading[0];
        const dto1Unload = dto1.pointsUnloading[0];
        const dto2Unload = dto2.pointsUnloading[0];
        if (!dto1Load || !dto2Load || !dto1Unload || !dto2Unload) {
            return false;
        }

        return (
            dto1Load.loadUnitSets.length !== dto2Load.loadUnitSets.length ||
            dto1Unload.loadUnitsToOrderMappings.length !==
                dto2Unload.loadUnitsToOrderMappings.length
        );
    }

    function getIsWaybillDraftAndAvizationRequires() {
        return (
            dto &&
            dto.status === WaybillStatusEnum.DRAFT &&
            dto.avisationStatus === WaybillStatusAvizationEnum.REQUIRES
        );
    }

    function indicator() {
        if (!dto) return null;

        const iconName = dto.isValid ? 'checkmark' : 'exclamation triangle';
        const iconColor = dto.isValid ? 'green' : 'orange';
        let text = dto.isValid ? 'Заявка готова к отправке'.t : '';

        const error = getValidationError([dto, ...routes], fieldConfigs);
        const errors = dto.isValid ? '' : error;
        const style = { fontSize: '13px' };

        return (
            <div>
                <Icon name={iconName} color={iconColor} style={{ marginBottom: '1px' }} />
                <span style={style}>
                    {text} {errors}
                </span>
            </div>
        );
    }

    function findIndexPointAfterAndBeforeTimeSlot(data, dto) {
        const pointsAll = dto.pointsLoading.concat(dto.pointsUnloading);

        const findPointsAfter = pointsAll.filter(i => data.arrivalDatePlan !== null && i.arrivalDatePlan === data.arrivalDatePlan && i.positionNumber > data.positionNumber);

        const findPointsBefore = pointsAll.filter(i => data.arrivalDatePlan !== null && i.arrivalDatePlan === data.arrivalDatePlan && i.positionNumber < data.positionNumber);

        const findIndexPointAfter = findPointsAfter.find(
            i =>
                (data.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan.from < data.arrivalTimeslotPlan.from) ||
                (data.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan.from === data.arrivalTimeslotPlan.from),
        );
        const findIndexPointBefore = findPointsBefore.find(
            i =>
                (data.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan.from > data.arrivalTimeslotPlan.from) ||
                (data.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan &&
                    i.arrivalTimeslotPlan.from === data.arrivalTimeslotPlan.from),
        );
        return !!findIndexPointAfter || !!findIndexPointBefore;
    }

    function isValidDataAndTime(data) {
        const pointsAll = data ? data.pointsLoading.map(item => ({...item, isLoading: true})).concat(data.pointsUnloading) : [];

        if (data.shippingType === WaybillTypeEnum.FTL) {
            const findIndexPointBefore = pointsAll.find(i => i.positionNumber === 1 && !i.isLoading && i.arrivalTimeslotPlan);

            return !(findIndexPointBefore && pointsAll.find(i => i.isLoading && i.arrivalTimeslotPlan))
        }

        const findIndexList = pointsAll.map(i => findIndexPointAfterAndBeforeTimeSlot(i, data));
        return !findIndexList.includes(true);
    }

    function isServices() {
        const pointsAll = dto ? dto.pointsLoading.concat(dto.pointsUnloading) : [];
        const findIndexList = pointsAll.map(i => findIndexPointAfterAndBeforeTimeSlot(i));
        return !findIndexList.includes(true);
    }

    function updateData(prop, options = {}, routeId) {
        const {loadingsChanged, skipUpdate, isUpdatePickupType, ignoreUnsaved} = options;
        const indexOfRoute = routeId ? routes.findIndex(r => r.id === routeId) + 1 : 0;
        const newDto = routeId ? routes[indexOfRoute - 1] : dto;
        let dtoUpdated = getIndexWbPoints({...newDto, ...prop});

        if (isUpdatePickupType) {
            dtoUpdated = {
                ...dtoUpdated,
                pickupType: PickupTypeEnum.NOT_SPECIFIED,
            }
        }

        const hasToMirror = getHasToMirror(dtoUpdated) && !hasNewPositions(newDto, dtoUpdated);

        if (hasToMirror) {
            if (options.unloadingsChanged) {
                const newMax = getUnloadPonitMax(dtoUpdated);
                const prevMax = getLoadPointMax(dtoUpdated);
                if (newMax > prevMax) {
                    dtoUpdated = setLoadPonitMax(dtoUpdated, newMax);
                }
            } else {
                const newMax = getLoadPointMax(dtoUpdated);
                const prevMax = getUnloadPonitMax(dtoUpdated);
                if (newMax > prevMax) {
                    dtoUpdated = setUnloadPonitMax(dtoUpdated, newMax);
                }
            }
        }

        if (loadingsChanged) {
            adjustLoadCapacity(dtoUpdated);
        }
        delete dtoUpdated['providerName'];

        const setData = () => {
            !routeId ? setDto(dtoUpdated) : setRoutes(routes => {
                let newRoutes = [...routes];
                newRoutes[indexOfRoute - 1] = dtoUpdated;
                return newRoutes;
            });
        };

        let dtoArray = [dto, ...routes];
        dtoArray[indexOfRoute] = dtoUpdated;

        if (skipUpdate) {
            setData();
        } else {
            const verror = getValidationError(dtoArray, fieldConfigs);

            dtoArray.forEach(dto => {
               dto.isValid = !verror;
            });

            setData();

            setDtoError(verror);

            if (((isDraft && !isAuction) || isAuctionDraft) && !ignoreUnsaved) {
                if (isValidDataAndTime(dtoUpdated)) {
                    dtoArray[indexOfRoute] = removeIds(dtoUpdated);
                    lastSavePromise.current = !isAuction
                        ? updateDataWithTimeout(dtoArray, fetchData)
                        : updateAuctionDataWithTimeout(removeIds(dtoUpdated), fetchData);
                }
            } else {
                setDto(dtoArray[0]);
                routes && setRoutes(dtoArray.slice(1));
            }
        }
        const errorFormWaybill = getValidationError(dtoArray, fieldConfigs);
        const canSave = errorFormWaybill === null;
        setCanSave(canSave);
        !ignoreUnsaved && setHasUnsaved(true);
    }

    useEffect(() => {
        !hasUnsaved && setCanSave(false);
    }, [hasUnsaved]);

    function adjustLoadCapacity(wb, restrictions = cargoRestrictions) {
        if (wb.shippingType === WaybillTypeEnum.FTL) {
            const currentCapacity = wb.vehicleCapacity || 0;

            const minRequiredCapacity = getMinCapacity(wb, restrictions);

            if (minRequiredCapacity > currentCapacity) {
                wb.vehicleCapacity = minRequiredCapacity;

                toast.info(`${'Грузоподъемность изменена на'.t} ${minRequiredCapacity} ${'кг'}`);
            }
        }
    }

    function onCloseAvizModal() {
        setIsOpenAvizModal(false);
    }

    async function loadClientRestrictions() {
        const companyId = dto && dto.clientId;
        if (!companyId) {
            return;
        }
        const configs = await getCompanyFieldConfigs(companyId);
        const restrictions = await getCompanyCargoRestrictions(companyId);

        setFieldConfigs(configs);
        setCargoRestrictions(restrictions);
    }

    useEffect(() => {
        loadClientRestrictions();
    }, [dto && dto.clientId]);

    useEffect(() => {
        fetchData();
        getIsEdit();
    }, [wbId]);

    const getIsEdit = async () => {
        const result = await wbIsEdit(wbId);
        setIsEdit(result && result.value);
    };

    useEffect(() => {
        contextFooter.setIndicator(indicator);
        return () => {
            contextFooter.setIndicator(null);
        };
    }, [dto, routes]);

    useEffect(() => {
        if (dto.status === WaybillStatusEnum.DRAFT) {
            if (isAuction && !dto.auction) {
                setDto(dto => ({
                    ...dto,
                    shippingType: WaybillTypeEnum.FTL,
                    auction: {
                        auctionStatus: AuctionStatusEnum.DRAFT
                    }
                }));
            }
        }
    }, [dto.status, location]);

    useEffect(() => {
        if (!!(dto.status !== WaybillStatusEnum.DRAFT || isAuction) && hasUnsaved) onBeforeUnloadOn();
        else onBeforeUnloadOff();
        return () => onBeforeUnloadOff();
    }, [dto.status, hasUnsaved]);

    useEffect(() => {
        if (dto && dto.shippingType && (dto.shippingType === 'LTL' || dto.shippingType === 'PlanReturn')) {
            setDto(prevState => {
                const pointsLoadingNew = prevState
                    ? prevState.pointsLoading.map(i => {
                          i.services = [];
                          return i;
                      })
                    : [];
                const dtoNew = { ...prevState, pointsLoading: pointsLoadingNew };
                return dtoNew;
            });
        }
    }, [dto && dto.shippingType]);

    const auctionEditStatus = AuctionEditStatuses.includes((dto.auction || {}).auctionStatus);

    const setProtectionCosts = async (dto) => {
        const data = await getAuctionCargoAndTermProtectionCostsWithTimeout(dto);

        if (data) {
            updateData({
                auction: {
                    ...dto.auction,
                    ...data
                }
            });
        }
    };

    const protectionCostsClear = useCallback(debounce((dto) => {
        updateData({
            auction: {
                ...dto.auction,
                cargoInsuranceWithoutVATCost: null,
                termInsuranceWithoutVATCost: null
            }
        });
    }, 1000), [updateData]);

    useEffect(() => {
        if (isAuction && auctionEditStatus) {
            if (
                (dto.pointsLoading || []).length
                && (dto.pointsUnloading || []).length
                && ((dto.pointsUnloading || [])[0].services || []).find(s => [PointServiceEnum.CARGO_PROTECTION, PointServiceEnum.TERM_PROTECTION].includes(s.key))
                && dto.clientId
                && dto.cargoCost
            ) {
                setProtectionCosts(dto);
            } else if (dto.auction.cargoInsuranceWithoutVATCost || dto.auction.termInsuranceWithoutVATCost) {
                protectionCostsClear(dto);
            }
        }
    }, [
        isAuction,
        ((dto.pointsLoading || [])[0] || {}).id,
        ((dto.pointsUnloading || [])[0] || {}).id && (((dto.pointsUnloading || [])[0] || {}).services || []).length,
        dto.clientId,
        dto.cargoCost
    ]);

    const [auctionHeight, setAuctionHeight] = useState(0);
    const [errorsAuction, setErrorsAuction] = useState([]);
    const canAuctionEdit = contextUser.current.permissions.includes(UserPermissions.AUCTIONS_EDIT);

    const ro = useRef(new ResizeObserver(el => {
        setAuctionHeight(el[0].target.offsetHeight)
    }))

    const resize = useCallback((container) => {
        if (container) ro.current.observe(container)
        else if (ro.current) {
            ro.current.disconnect()
            setAuctionHeight(0)
        }
    }, [ro.current])

    if (loading && (!dto || !dto.id)) {
        return <Spinner show />;
    } else {
        const sendable = [
            WaybillStatusEnum.SUBMITTED,
            WaybillStatusEnum.ON_APPROVAL,
            WaybillStatusEnum.CARGO_DELIVERED,
            WaybillStatusEnum.DOCS_SENT,
            WaybillStatusEnum.EXECUTING,
            WaybillStatusEnum.CARGO_DELIVERED
        ].includes(dto.status);

        const actionMenu = (
            <Dropdown
                id='dropdown-menu_actions'
                icon="caret down"
                floating
                button
                className="icon button-complete-wiz"
                disabled={dto.__canEdit}
            >
                <Dropdown.Menu>
                    <Dropdown.Item id='btn_newWaybill' onClick={() => goToNew()}>
                        <Icon name="redo" />
                        <T>Начать новую заявку</T>
                    </Dropdown.Item>
                    {/* <Dropdown.Item><Icon name="trash" />Удалить черновик</Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
        );

        /*const saveMenu = (
            <Dropdown
                icon="caret down"
                floating
                button
                className="icon button-complete-wiz"
                disabled={!sendable}
            >
                <Dropdown.Menu>
                    {/!*  <Dropdown.Item onClick={sendToFm} disabled={!dto.isValid || !sendable}>
                        <Icon name="upload"/>
                        <T>Отправить в FM</T>
                    </Dropdown.Item> *!/}
                    {dto.avisationStatus !== WaybillStatusAvizationEnum.CONFIRMED &&
                    dto.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES &&
                    !(
                        dto.avisationStatus === WaybillStatusAvizationEnum.FILL_DRIVER &&
                        dto.status > WaybillStatusEnum.DRAFT
                    ) ? (
                        <Dropdown.Item disabled={!hasUnsaved} onClick={save}>
                            <Icon name="save" />
                            <T>Сохранить</T>
                        </Dropdown.Item>
                    ) : null}
                </Dropdown.Menu>
            </Dropdown>
        );
*/
        let buttonSend;

        const buttonChangeShippingMethod = (
            <Button.Group color="green">
                <WbProcessing dto={dto} lastSavePromise={lastSavePromise.current}>
                    <Button id='btn_changeShippingMethod' primary disabled={!dto.isValid}>
                        <T>Изменить способ доставки</T>
                    </Button>
                </WbProcessing>
                {actionMenu}
            </Button.Group>
        );
        if (dto.__canEdit || isClient) {
            buttonSend = (
                isDraft ?

                    <Button id='btn_saveSendToFm' color='blue' disabled={!dto.isValid || !sendable} onClick={sendToFm}>
                        <Icon name="upload"/>
                        <T>Сохранить и отправить в FM</T>
                    </Button> :

                    <Button.Group color="green">
                        {
                            canChangePickupType ?
                                <WbProcessing dto={dto} sendToFm={sendToFm}>
                                    <Button id='btn_saveSendToFm'> <T>Сохранить и отправить в FM</T> </Button>
                                </WbProcessing>
                                : <Button id='btn_saveSendToFm' primary onClick={sendToFm} disabled={!dto.isValid || !sendable}>
                                    <T>Сохранить и отправить в FM</T>
                                </Button>
                        }
                    </Button.Group>
            );
        } else {
            buttonSend = !isPoolingDraft ? (
                <Button.Group color="green">
                    <WbProcessing dto={dto} lastSavePromise={lastSavePromise.current}>
                        <Button id='btn_forExecution' primary disabled={!dto.isValid}>
                            <T>На исполнение</T>
                        </Button>
                    </WbProcessing>
                    {actionMenu}
                </Button.Group>
            ) : null;
        }

        const wbStatus = getWaybillStatusTexts(dto);

        const headerGrid = (
            <Grid verticalAlign="middle" columns={3} style={{ minHeight: '87px' }}>
                <GridColumn>
                    {!isAuction && <div className="display-flex">
                        <WaybillStatusOrder statusOrder={wbStatus.statusOrder}/>
                        <WaybillStatusShipping
                            statusShipping={wbStatus.statusShipping}
                            className="m-l-10"
                        />
                    </div>}
                </GridColumn>
                <GridColumn textAlign="center">
                    <h3 className="m-0 p-0">
                        <T>Адреса и грузы</T>
                    </h3>
                </GridColumn>
                <GridColumn textAlign="right">
                    {!isAuction && buttonSend}
                </GridColumn>
            </Grid>
        );

        const auctionActionMenu = (
            <Dropdown
                icon="caret down"
                floating
                button
                className="icon button-complete-wiz"
                disabled={!canAuctionEdit}
                id='dropdown-menu_auctionActions'
            >
                <Dropdown.Menu>
                    <Dropdown.Item id='btn_auctionSave' disabled={!canAuctionEdit} onClick={() => auctionSave(() => {
                        setHasUnsaved(false);
                        setTimeout(toAuctionGrid, 100);
                    })} primary>
                        <T>Сохранить</T>
                    </Dropdown.Item>
                    {[AuctionStatusEnum.BIDDING, AuctionStatusEnum.REQUIRES_DECISION].includes((dto.auction || {}).auctionStatus) && <AuctionStartModal unsaved={hasUnsaved} dto={dto} save={auctionSave} isExtension>
                        <Dropdown.Item id='btn_auctionExtend' disabled={loading}>
                            <T>Продлить аукцион</T>
                        </Dropdown.Item>
                    </AuctionStartModal>}
                    {(dto.auction || {}).id && !AuctionCancelledStatuses.includes((dto.auction || {}).auctionStatus) && <Dropdown.Item id='btn_auctionCancel' onClick={cancel} disabled={loading || !isEdit}>
                        <T>Отменить аукцион</T>
                    </Dropdown.Item>}
                    {([AuctionStatusEnum.BIDDING, AuctionStatusEnum.REQUIRES_DECISION].includes((dto.auction || {}).auctionStatus) && (dto.auction || {}).bestBet) &&
                        <CompleteWithWinnerBtn
                            as={Dropdown.Item}
                            id='btn_auctionCompleteWithWinner'
                            onClick={completeWithWinner}
                            wb={dto.auction}
                        >
                            <T>Разместить заявку</T>
                        </CompleteWithWinnerBtn>
                    }
                    <HistoryModal>
                        <Dropdown.Item
                            id='btn_historyModal'
                            disabled={loading}
                        >
                            <T>История</T>
                        </Dropdown.Item>
                    </HistoryModal>
                </Dropdown.Menu>
            </Dropdown>
        );

        const toAuctionGrid = () => history.push('/waybills/auctions');

        const auctionBtns = <Button.Group color="green">
            {[AuctionStatusEnum.DRAFT, AuctionStatusEnum.CREATED].includes((dto.auction || {}).auctionStatus) && <AuctionStartModal
                refresh={fetchData}
                unsaved={hasUnsaved}
                dto={dto}
                save={auctionSave}
            >
                <Button id='btn_auctionStart' disabled={loading}>
                    <T>Сохранить и запустить</T>
                </Button>
            </AuctionStartModal>}
            {auctionActionMenu}
        </Button.Group>;

        const auctionHeader = (
            <Grid verticalAlign="middle" columns={3} style={{ minHeight: '87px' }}>
                <GridColumn>
                    <div className="display-flex">
                        <WaybillStatusOrder statusOrder={wbStatus.statusAuction} />
                        <WaybillStatusShipping
                            statusShipping={wbStatus.statusShipping}
                            className="m-l-10"
                        />
                    </div>
                </GridColumn>
                <GridColumn textAlign="center">
                    <h3 className="m-0 p-0">
                        <T>Данные аукциона</T>
                    </h3>
                </GridColumn>
                <GridColumn textAlign="right">
                    {auctionBtns}
                </GridColumn>
            </Grid>
        );

        const promtFunc = (location) => {
            setNewLocation(location);
            return false;
        };

        const onConfirm = () => {
            let location = {...newLocation};
            setNewLocation(null);
            history.push(location);
        };

        const disabledForAuction = isAuction && dto.auction
            && ![
                AuctionStatusEnum.DRAFT,
                AuctionStatusEnum.CREATED,
            ].includes(dto.auction.auctionStatus);

        const addRoute = async () => {
            await createRoute(dto.id).then(res => {
                if ((res || {}).newId) {
                    redirect(res.newId, true);
                } else if (isDraft && !isAuction) {
                    fetchData();
                } else {
                    const newRoute = new Waybill({
                        ...((res || {}).waybill || {}),
                        cargoType: dto.cargoType,
                    });
                    const newRoutes = [...routes, newRoute];
                    setRoutes(newRoutes);
                    setIsValid(dto, newRoutes);
                }
            });
        };

        const onSave = (dto, newRoutes) => {
            const dtos = setIsValid(dto, newRoutes);
            if (isDraft && !isAuction) {
                lastSavePromise.current = updateDataWithTimeout(dtos.map(r => removeIds(r)), fetchData);
            }
        };

        const changeParamsPoints = (point, pointFrom) => {
            return (point && pointFrom) ? {
                ...pointFrom,
                id: point.id,
                waybillId: point.waybillId,
                index: point.index,
                type: point.type,
                loadUnitSets: (point.type === pointFrom.type ? pointFrom : point).loadUnitSets.map(loadUnit => {
                    return {
                        ...loadUnit,
                        id: 0,
                        pointFromId: point.id
                    };
                }),
                loadUnitsToOrderMappings: (point.type === pointFrom.type ? pointFrom : point).loadUnitsToOrderMappings.map(loadUnit => {
                    return {
                        ...loadUnit,
                        id: 0,
                        pointToId: point.id
                    };
                }),
                services: (point.type === pointFrom.type ? pointFrom : point).services
            } : pointFrom;
        };


        const newPointParams = (waybillPoint, waybillId) => {
            return {
                ...waybillPoint,
                waybillId: waybillId,
                id: Math.random(),
                loadUnitSets: waybillPoint.loadUnitSets.map(loadUnit => {
                    return {
                        ...loadUnit,
                        id: 0
                    };
                }),
                loadUnitsToOrderMappings: waybillPoint.loadUnitsToOrderMappings.map(loadUnit => {
                    return {
                        ...loadUnit,
                        id: 0
                    };
                }),
            };
        };

        const changePoints = async (data) => {
            const {
                waybillIdFrom,
                waybillIdTo,
                waybillPointIdFrom,
                waybillPointIdTo
            } = data;

            const waybillFrom = routes.find(r => r.id === waybillIdFrom);
            const waybillTo = routes.find(r => r.id === waybillIdTo);

            const waybillPointFrom = waybillFrom && [...waybillFrom.pointsLoading, ...waybillFrom.pointsUnloading].find(p => p.id === waybillPointIdFrom);
            const waybillPointTo = waybillTo && [...waybillTo.pointsLoading, ...waybillTo.pointsUnloading].find(p => p.id === waybillPointIdTo);


            let waybillPoint1 = changeParamsPoints(waybillPointFrom, waybillPointTo);
            let waybillPoint2 = changeParamsPoints(waybillPointTo, waybillPointFrom);

            const isOnlyOne = !!(waybillPoint1 || waybillPoint2) && !(waybillPoint1 && waybillPoint2);

            let waybill1 = null;
            let waybill2 = null;

            if (waybillIdFrom !== waybillIdTo) {

                if (isOnlyOne) {
                    const isLoading = (waybillPoint1 || waybillPoint2).type === 0;

                    const point1 = waybillPoint1 && newPointParams(waybillPoint1, waybillIdFrom);
                    const point2 = waybillPoint2 && newPointParams(waybillPoint2, waybillIdTo);

                    waybill1 = {
                        ...waybillFrom,
                        pointsLoading: isLoading
                            ? (point1
                                ? [...waybillFrom.pointsLoading, point1]
                                : waybillFrom.pointsLoading.filter(p => p.id !== waybillPoint2.id))
                            : waybillFrom.pointsLoading,
                        pointsUnloading: !isLoading
                            ? (point1
                                ? [...waybillFrom.pointsUnloading, point1]
                                : waybillFrom.pointsUnloading.filter(p => p.id !== waybillPoint2.id))
                            : waybillFrom.pointsUnloading,
                    };

                    waybill2 = {
                        ...waybillTo,
                        pointsLoading: isLoading
                            ? (point2
                                ? [...waybillTo.pointsLoading, point2]
                                : waybillTo.pointsLoading.filter(p => p.id !== waybillPoint1.id))
                            : waybillTo.pointsLoading,
                        pointsUnloading: !isLoading
                            ? (point2
                                ? [...waybillTo.pointsUnloading, point2]
                                : waybillTo.pointsUnloading.filter(p => p.id !== waybillPoint1.id))
                            : waybillTo.pointsUnloading,
                    };
                } else {
                    waybill1 = {
                        ...waybillFrom,
                        pointsLoading: replaceObjectById(
                            waybillFrom.pointsLoading,
                            waybillPoint1.id,
                            waybillPoint1
                        ),
                        pointsUnloading: replaceObjectById(
                            waybillFrom.pointsUnloading,
                            waybillPoint1.id,
                            waybillPoint1
                        ),
                    };
                    waybill2 = {
                        ...waybillTo,
                        pointsLoading: replaceObjectById(
                            waybillTo.pointsLoading,
                            waybillPoint2.id,
                            waybillPoint2
                        ),
                        pointsUnloading: replaceObjectById(
                            waybillTo.pointsUnloading,
                            waybillPoint2.id,
                            waybillPoint2
                        ),
                    };
                }
            } else {

                if (isOnlyOne) {
                    const waybillPointNew = waybillPoint1 || waybillPoint2;
                    waybillPointNew.services = [];
                    const isLoading = waybillPointNew.type === 1;

                    waybill1 = {
                        ...waybillFrom,
                        pointsLoading: isLoading
                            ? [...waybillFrom.pointsLoading, {...waybillPointNew, type: 0}]
                            : waybillFrom.pointsLoading.filter(p => p.id !== waybillPointNew.id),
                        pointsUnloading: !isLoading
                            ? [...waybillFrom.pointsUnloading, {...waybillPointNew, type: 1}]
                            : waybillFrom.pointsUnloading.filter(p => p.id !== waybillPointNew.id),
                    };
                } else {
                    waybill1 = {
                        ...waybillFrom,
                        pointsLoading: replaceObjectById(
                            waybillFrom.pointsLoading,
                            !waybillPoint1.type ? waybillPoint1.id : waybillPoint2.id,
                            !waybillPoint1.type ? waybillPoint1 : waybillPoint2
                        ),
                        pointsUnloading: replaceObjectById(
                            waybillFrom.pointsUnloading,
                            waybillPoint1.type ? waybillPoint1.id : waybillPoint2.id,
                            waybillPoint1.type ? waybillPoint1 : waybillPoint2
                        ),
                    };
                }
            }

            setRoutes(routes => {
                let newRoutes = replaceObjectById(routes, waybill1.id, waybill1);
                if (waybill2) newRoutes = replaceObjectById(newRoutes, waybill2.id, waybill2);
                onSave(dto, newRoutes);
                return newRoutes;
            });
        };

        const changeRoutes = async (data) => {
            const {
                waybillIdFrom,
                waybillIdTo
            } = data;

            const newRoutes = swapObjectsById(routes, waybillIdFrom, waybillIdTo);

            setRoutes(newRoutes);
            onSave(dto, newRoutes);
        };

        const redirect = (id, isReplace) => {
            const fn = isReplace ? history.replace : history.push;
            fn(`/waybills/${id}/edit`);
        };

        const deleteRoute = async (id, save) => {
            if (isDraft && !isAuction) {
                await waybillDelete([id]).then(res => {
                    (id.toString() === wbId.toString()) ? redirect(dto.id, true) : fetchData();
                });
            } else {
                let newRoutes = routes.filter(r => r.id !== id);
                const verror = getValidationError([dto, ...newRoutes], fieldConfigs);
                const dtoUpdated = {
                    ...dto,
                    isValid: !verror
                };
                setDto(dtoUpdated);

                if (routes.length) {
                    newRoutes = newRoutes.map(r => ({...r, isValid: !verror}));
                    setRoutes(newRoutes);
                }

                if (save) await updateWaybill([dtoUpdated, ...newRoutes]).then(res => {
                    setHasUnsaved(false);
                    (res.data.newId || id.toString() === wbId.toString()) && setTimeout(() => redirect(res.data.newId || dto.id), 100);
                });
            }
        };

        return (
            <ContextWb.Provider
                value={{
                    wb: dto,
                    update: update,
                    save: updateData,
                }}
            >
                <ContextFieldConfigs.Provider value={fieldConfigs}>
                    <Grid className="p-t-0 m-b-0" style={{ marginTop: '-1px' }}>
                        <Grid.Row stretched className="p-t-0 p-b-0">
                            <Grid.Column className="p-t-0" style={{width: "18%"}}>
                                <Segment className="step-wrapper" style={{
                                    paddingTop: '34px',
                                    paddingRight: '20px',
                                    paddingLeft: '20px',
                                    paddingBottom: '20px',
                                    zIndex: "1",
                                    height: "calc(100vh - 87px)",
                                    overflowX: "auto"
                                }}>
                                    <WbEditMain
                                        withRoutes={routes && routes.length}
                                        disabled={disabledForAuction}
                                        isAuction={isAuction}
                                        setLoading={setLoading}
                                        dto={dto}
                                        isClient={isClient || isPoolingEdit}
                                        updateData={updateData}
                                        cargoRestrictions={cargoRestrictions}
                                        fieldConfigs={fieldConfigs}
                                        cargoCostIsRequired={cargoCostIsRequired(dto)}
                                    />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column
                                className="editor-rightcol"
                                style={{
                                    marginLeft: '-15px',
                                    width: '82%',
                                    maxHeight: 'calc(100vh - 88px)',
                                    overflowY: 'auto'
                                }}
                            >
                                <Segment style={{
                                    paddingTop: '13px',
                                    paddingLeft: '18px',
                                    paddingBottom: 0
                                }}>
                                    {auctionView && <div ref={resize}>
                                        {auctionHeader}
                                        <Divider className="m-t-10" />
                                        <AuctionBlock
                                            disabled={!canAuctionEdit}
                                            errors={errorsAuction}
                                            setError={setErrorsAuction}
                                            dto={dto}
                                            updateData={updateData}
                                        />
                                    </div>}
                                    {headerGrid}
                                    <Divider className="m-t-10" />
                                    <div
                                        style={{
                                            paddingTop: '5px',
                                            overflowY: 'hidden',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        <WbEditPoints
                                            isAuction={isAuction}
                                            routes={routes}
                                            disabled={disabledForAuction || loading}
                                            auctionHeight={auctionHeight}
                                            isEdit={isEdit}
                                            isClient={isClient || isPoolingEdit}
                                            dto={dto}
                                            updateData={updateData}
                                            isPoolingEdit={isPoolingEdit}
                                            addRoute={addRoute}
                                            changePoints={changePoints}
                                            changeRoutes={changeRoutes}
                                            deleteRoute={deleteRoute}
                                            fieldConfigs={fieldConfigs}
                                        />
                                    </div>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {loading && dto && dto.id && <Spinner show />}
                    <WbAvizModal
                        open={isOpenAvizModal}
                        onClose={onCloseAvizModal}
                        selectedRows={waybillsWithVisit}
                        optionsTypeTransport={LoadCapacityOptions}
                        onAvisation={() => {
                            onCloseAvizModal();
                            setWaybillsWithVisit([]);
                            history.push(`/waybills/`);
                        }}
                    />
                    <WbEditAvisTimeslot
                        onClose={()=>setEditTimeslotData(null)}
                        editTimeslotData={editTimeslotData}
                    />
                </ContextFieldConfigs.Provider>
                <Prompt when={!!(((dto.status !== WaybillStatusEnum.DRAFT) || isAuction) && hasUnsaved && !newLocation)} message={promtFunc}/>
                <Confirm open={!!newLocation}
                         dimmer="inverted"
                         content={'Изменения в заявке не будут сохранены'.t}
                         confirmButton={'Ок'.t}
                         cancelButton={'Вернуться к редактированию заявки'.t}
                         onCancel={() => setNewLocation(null)}
                         onConfirm={onConfirm}
                />
            </ContextWb.Provider>
        );
    }
}
