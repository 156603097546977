import React from 'react';
import {Feed, Icon, Table} from 'semantic-ui-react';

export default function HistoryItem({ date, userName, title, text, canComment, icon = "check", tableData }) {

    const table = () => {
        const tbl = JSON.parse(tableData);
        const rows = tbl.rows || [];

        return <div className='history-table'>
            <Table celled structured>
                <Table.Header>
                    <Table.Row>
                        {
                            (rows[0] || {}).columns.map(r => <Table.HeaderCell key={r.name}>{r.name}</Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        rows.map((t, i) => (<Table.Row key={i}>
                            {
                                t.columns.map((r, i) => <Table.Cell rowSpan={r.rowSpan} key={i}>
                                    {
                                        r.oldValue === r.newValue
                                            ? r.newValue
                                            : <>
                                                {r.oldValue && <div className='history-table__value_old'>{r.oldValue}</div>}
                                                {r.newValue && <div className='history-table__value_new'>{r.newValue}</div>}
                                            </>
                                    }
                                </Table.Cell>)
                            }
                        </Table.Row>))
                    }
                </Table.Body>
            </Table>
        </div>;
    };

    return (
        <Feed.Event>
            <Feed.Label icon={icon}/>
            <Feed.Content>
                <Feed.Summary>
                    {title}
                    <Feed.User className="m-l-5">{userName}</Feed.User>
                    <Feed.Date>{date} мск</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text style={{whiteSpace: 'pre-line'}}>{text}</Feed.Extra>
                {canComment &&
                    <Feed.Meta>
                        <Feed.Like><Icon name='edit'/>добавить комментарий</Feed.Like>
                    </Feed.Meta>
                }
                {
                    tableData && table()
                }
            </Feed.Content>
        </Feed.Event>
    );
}
