import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import {
    AuctionStatusOptions,
    VehicleCapacityOptions,
    WaybillSources,
    WaybillStatusAnomaly,
    WaybillStatusAnomalyOptions,
    WaybillStatusAnomalyOptionsIsRefund,
    WaybillStatusAvizationOptions,
    WaybillStatusEnum,
    WaybillStatusOptions,
    WaybillTypeEnum,
} from '../../../api/model/Waybill';
import {Icon, Label, List, Popup, Progress} from 'semantic-ui-react';
import {WaybillStepStatusEnum} from '../../../api/model/WaybillStep';
import WbProgressPopup from './wbProgressPopup/WbProgressPopup';
import {
    AuctionTypeOptions,
    CargoTypeOptions,
    PickupTypeEnum,
    PickupTypeOptions,
    ThermoModeOptions
} from '../../../api/model/Enums';
import T from '../../../components/Translate';
import {downloadFilePod} from "../../../api/waybillDocs";
import downloadFile from "../../../services/downloadFile";
import {servicesConst} from "../../../api/model/PointService";
import {fmRelationValue} from "../../../services/values";

const needPickupIconStl = {
    fontSize: '15px',
    verticalAlign: 'text-bottom',
    marginLeft: '3px',
    color: '#a09a9a',
    float: 'right',
};

const typeColors = {
    LTL: 'teal',
    FTL: 'violet',
    Rail: 'olive',
    RailLTL: 'blue',
    Return: 'orange',
    PlanReturn: 'red',
};

const formatCost = function(value) {
    if (Intl.NumberFormat)
        //return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' }).format(value);
        return new Intl.NumberFormat('ru-RU', {
            style: 'decimal',
            minimumFractionDigits: 0,
        }).format(value);
    //return new Intl.NumberFormat('ru-RU').format(value);
    else return value; // если браузер не поддерживает этот объект, оставляем все как есть, без обработки
};

export default function WbGridRowElement({el: key, data = {}, canEdit}) {

    const id = data.id;
    const isEditable = data.status === WaybillStatusEnum.DRAFT;
    const val = data[key];

    function handleDocumentDownload() {
        alert('downloading!');
    }

    const handleDownload = async () => {
        const result = await downloadFilePod(data.id);

        downloadFile(result);
    };


    switch (key) {
        case 'id':
        case 'fmid':
        case 'waybillId':
            let linkClass = 'table-link ';

            if (isEditable) {
                linkClass = data.isValid
                    ? (linkClass += 'table-link-green')
                    : (linkClass += 'table-link-grey');
            }
            return (
                <>
                    {data.isAuction && (
                        <Icon style={needPickupIconStl} name="legal" title="Аукцион"/>
                    )}
                    <div className={linkClass}>{val}</div>
                    {data.status !== WaybillStatusEnum.DRAFT &&
                    data.pickupType === PickupTypeEnum.FM && (
                        <Icon
                            style={needPickupIconStl}
                            name="truck"
                            title={'Pickup силами FM'.t}
                        />
                    )}
                    {data.isPooling && (
                        <Icon style={needPickupIconStl} name="cube" title="Pooling заявка"/>
                    )}
                </>
            );
        case 'parentPortalId': {
            let linkClass = 'table-link ';
            const draftRef = isEditable // (isEditable || canEdit)
                ? '/edit'
                : '/view/info';
            if (isEditable) {
                linkClass = data.isValid
                    ? (linkClass += 'table-link-green')
                    : (linkClass += 'table-link-grey');
            }
            return <a
                href={`waybills/${data.parentWaybillId}${draftRef}`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className={linkClass}
            >
                {val}
            </a>;
        }
        case 'source': {
            const sourceData = WaybillSources.find(s => s.value === val);
            return sourceData
                ? <>
                    <Label style={{ textAlign: 'center' }}>
                        {(sourceData || {}).text}
                    </Label>
                </>
                : '';
        }
        case 'waybillType':
            return (
                val ? <>
                    <Label style={{ textAlign: 'center' }}>
                        {val}
                    </Label>
                </> : null
            );
        case 'pickupType':
            const v = PickupTypeOptions.find(o => o.key === val);

            return v ? (
                <Label
                    style={{ textAlign: 'center', marginRight: '5px' }}
                    title={v.text}
                >
                    {v.text}
                </Label>
            ) : null;
        case 'type':
            return (
                <>
                    <Label color={typeColors[val]} style={{ textAlign: 'center' }}>
                        {val}
                    </Label>
                </>
            );
        case 'totalWeight':
        case 'totalWeightKgBrutto':
        case 'cost':
            //return <div style={{ textAlign: 'right', paddingRight: "10px"}}>{formatCost(val)}</div>;
            return val ? (
                <div style={{ textAlign: 'right', width: '53px' }}>{formatCost(val)}</div>
            ) : null;
        //return formatCost(val);

        case 'totalUnits':
            if (val) {
                return (
                    <React.Fragment>
                        {Object.keys(val).map(key => (
                            <span
                                key={key}
                                style={{
                                    textAlign: 'right',
                                    width: '35px',
                                    display: 'inline-block',
                                }}
                            >
                                {val[key]} <span style={{ color: '#9e9e9e' }}>{key}</span>&nbsp;
                            </span>
                        ))}
                    </React.Fragment>
                );
            }
            return null;

        case 'status':
        case 'auctionStatus':
            let o = (key === 'auctionStatus' ? AuctionStatusOptions : WaybillStatusOptions).find(o => o.key === val);

            return o ? (
                <Label
                    color={o.color}
                    style={{ textAlign: 'center', marginRight: '5px' }}
                    title={o.text.t}
                >
                    {o.text.t}
                </Label>
            ) : (
                <Label style={{ textAlign: 'center', marginRight: '5px' }}>{val}</Label>
            );
        case 'statusAnomaly':
            let value = (data['type'] !== WaybillTypeEnum.Return && WaybillStatusAnomalyOptionsIsRefund.includes(val)) ? WaybillStatusAnomaly.REFUND : val;
            let item = WaybillStatusAnomalyOptions.find(o => o.key === value);
            return item ? (
                <Label
                    color={item.color}
                    style={{textAlign: 'center', marginRight: '5px'}}
                    title={item.text.t}
                >
                    {item.text.t}
                </Label>
            ) : null;
        case 'avisationStatus':
            const statusAvization = WaybillStatusAvizationOptions.find(o => o.key === val);
            return statusAvization
                ? (
                    <Label
                        color={statusAvization.color}
                        style={{ textAlign: 'center', marginRight: '5px' }}
                        title={statusAvization.text.t}
                    >
                        {statusAvization.text.t}
                    </Label>
                )
                : (
                    <Label style={{ textAlign: 'center', marginRight: '5px' }}>{val}</Label>
                );
        case 'shippingTemperatureCondition':
            const tempCondition = ThermoModeOptions.find(o => o.key === val);
            return tempCondition ? tempCondition.text : null;
        case 'cargoType':
            const cargoType = CargoTypeOptions.find(o => o.key === val);
            return cargoType ? cargoType.text : null;
        case 'vehicleCapacity':
            const vehicleCapacity = VehicleCapacityOptions.find(o => o.key === val);
            return vehicleCapacity ? vehicleCapacity.text : null;
        case 'auctionType':
            const auctionType = AuctionTypeOptions.find(o => o.key === val);
            return auctionType ? auctionType.text : null;
        case 'readiness':
            const showRoute = [
                WaybillStatusEnum.EXECUTING,
                WaybillStatusEnum.CARGO_DELIVERED,
                WaybillStatusEnum.DOCS_SENT,
            ].includes(data.status);

            const isCompleted = [
                WaybillStatusEnum.CARGO_DELIVERED,
                WaybillStatusEnum.DOCS_SENT,
            ].includes(data.status);

            const progress = isCompleted
                ? 100
                : val && val.length
                ? parseInt(
                      ((val.filter(pt => pt.pointStatus === WaybillStepStatusEnum.DONE).length ||
                          0) /
                          val.length) *
                          100,
                  )
                : 0;

            return showRoute ? (
                <WbProgressPopup data={val}>
                    <Progress
                        value={progress}
                        size="small"
                        progress="value"
                        color={isCompleted ? 'blue' : 'green'}
                    />
                </WbProgressPopup>
            ) : (
                <Progress value={0} size="small" progress="value" color="red" />
            );

        case 'loadingDate':
        case 'deliveryDate':
        case 'deadline':
            return val ? moment(val).format('DD.MM.YYYY HH:mm') : null;
        case 'auctionStart':
        case 'auctionEnd':
            return val ? moment.utc(val).local().format('DD.MM.YYYY HH:mm') : null;
        case 'pointLoadingAddress':
        case 'pointUnloadingAddress':
            return (
                <div
                    style={{
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span title={val && val.toString()}>{val}</span>
                </div>
            );
        case 'shipto':
            let index = 0;
            let locations =
                val &&
                val.map(a => {
                    return (
                        <React.Fragment key={index++}>
                            {a.settlement},{' '}
                            <span style={{ color: 'lightgrey' }}>{a.streetName}</span>
                        </React.Fragment>
                    );
                });
            return (
                <div
                    style={{
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span title={val && val.toString()}>{locations}</span>
                </div>
            );

        case 'refs':
        case 'warehouseOrderNo':
            return (
                <div
                    style={{
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span title={val && val.toString()}>{val}</span>
                </div>
            );

        case 'torg12No':
        case 'torg12':
            return (
                <div
                    style={{
                        width: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span title={val && val.toString()}>{val}</span>
                </div>
            );

        case "document":
            return data.document
                ? <div className="a-underline-hover" onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    handleDownload();
                }}>
                    <T>Скачать POD</T>
                </div> : null;
        /*return data.document
            ? <a download
                className="a-underline-hover"
                target="_blank"
                rel="noopener noreferrer"
                href={`/api/waybills/${data.id}/docs/pod/download`}
                onClick={e => {
                    e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                }}><T>Скачать POD</T></a>
            : null;
                }}><T>Скачать POD</T></a>
            : null;*/
        case "document_bak":
            const canHaveDocs = data.status >= WaybillStatusEnum.EXECUTING;
            const fileIcon = 'file';
            return canHaveDocs && val && val.loaded ? (
                <Popup
                    hoverable
                    trigger={
                        <span className="table-document" onClick={handleDocumentDownload}>
                            <Icon name="attach" style={{ fontSize: '14px' }} />
                            {val.date}
                            {/* {moment(val.date).format("DD.MM.YYYY")} */}
                        </span>
                    }
                >
                    <Popup.Header className="m-b-10">Документы</Popup.Header>
                    <Popup.Content>
                        <List divided verticalAlign="middle" relaxed>
                            <List.Item>
                                <List.Icon name={fileIcon} />
                                <List.Content>
                                    <List.Header as="a">Транспортная накладная</List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name={fileIcon} />
                                <List.Content>
                                    <List.Header as="a" href="http://google.com" target="_blank">
                                        Этикетка на груз
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name={fileIcon} />
                                <List.Content>
                                    <List.Header as="a">Экспедиторская расписка</List.Header>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Popup.Content>
                </Popup>
            ) : null;
        case 'consignee':
            return <span title={val}>{val}</span>;
        case "services":
        case "loadingServices":
        case "unloadingServices":
            if (!val || !val.length) return null;
            const obj = val.map(item => `${servicesConst()[item.serviceType.toString()]}${item.value ? ': ' : ''}${item.value || ''}`);
            const str = obj && obj.length
                ? obj.join("; ")
                : null;

            return <div style={{
                width: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}><span title={str}>{str}</span></div>;
        case 'reservation':
            return <div>
                {val && val.deliveryDate ? `${new Date(val.deliveryDate).toLocaleDateString()} ${val.start && val.start.slice(0,5)} - ${val.end && val.end.slice(0,5)}` : ""}
            </div>;
        case 'tsunamiSendingMessage':
            return <div style={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}><span title={val}>{val}</span></div>;
        case 'isArchive':
            return val ? 'Да' : '';
        case "fmRelation":
            return fmRelationValue(val);
        default:
            return val || '';
    }
}
