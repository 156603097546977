import React from 'react';
import Dropzone from 'react-dropzone';
import {
    Button,
    Divider,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Icon,
    Message,
    Modal,
    Tab
} from 'semantic-ui-react';
import {importWaybills, waybillImportExel} from '../../../../../api/waybills';
import T from '../../../../../components/Translate';
import './index.css';
import {PickupTypeOptions} from "../../../../../api/model/Enums";
import {checkClientRequest, importByForm} from "../../../../../api/importForms";
import ImportFormSelect from "../../../../../components/modals/components/ImportFormSelect";
import {ContextUser} from "../../../../../services/context";
import {getUserCompanyOptions} from "../../../../../api/users";
import {ImportFormStatusEnum} from "../../../../../api/model/ImportForms";
import Tables from "./tables";
import {EXCEL_FORMATS} from "../../../../../api/globalConstants";

const ImportStatus = {
    START: 1,
    FILES_SELECTED: 2,
    IMPORTING: 3,
    SUCCESS: 4, // not used -> redirecting on success
    ERROR: 5,
    SENDING: 6,
    SENDING_ERROR: 7,
    SENDING_SUCCESS: 8,
};


export default class WbsImportForm extends React.Component {
    static contextType = ContextUser;

    state = {
        open: false,
        activeIndex: 0,
        openAll: false,
        status: ImportStatus.START,
        acceptedFiles: null,
        errors: "",
        pickupChoiceComplete: false,
        sendProgress: false,
        modalVersion: 0
    };


    setFmid = async () => {
        const values = await getUserCompanyOptions();
        if ((values || []).length === 1) {
            this.setState({fmid: values[0].value});
        }
    }

    isOneClient = () => !this.context.current.isAllCompanies
        && (this.context.current.companies || []).length === 1;

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {open, activeIndex} = this.state;
        if (
            (prevState.open !== open || prevState.activeIndex !== activeIndex)
            && activeIndex === 1
            && open
            && this.isOneClient()
        ) {
            this.setFmid();
        }
    }

    handleOpen = async () => {
        const {formName, func, clientName, checkClient} = this.props;

        const newStateValues = {
            open: true,
            formName,
            activeIndex: formName ? 1 : 0,
            clientName
        };

        if (checkClient) {
            const res = await checkClientRequest();
            this.setState({
                ...newStateValues,
                modalVersion: res,
                activeIndex: res === 1 ? 1 : 0,
            });
        } else {
            this.setState(newStateValues);
        }

        func && this.import();
    }

    handleClose = () => {
        const status = this.state.status;
        this.setState({
                open: false,
                status: ImportStatus.START
            },
            () => (this.props.refresh && (!this.props.func || (status > ImportStatus.SUCCESS))) && this.props.refresh()
        );
    }

    onDrop(acceptedFiles) {
        this.setState({status: ImportStatus.FILES_SELECTED, acceptedFiles});
    }

    async import() {
        const {func, history} = this.props;
        let formData = new FormData();
        const oldVersionIsActive = this.state.activeIndex === 0;
        !func && formData.append(`file${oldVersionIsActive ? 's' : ''}`, this.state.acceptedFiles[0]);

        this.setState({status: ImportStatus.IMPORTING}, async () => {
            try {
                let result;

                if (func) {
                    result = await func();
                } else {
                    result = !oldVersionIsActive
                        ? await importByForm(this.state.formName, formData)
                        : await waybillImportExel(formData);
                }

                if (oldVersionIsActive) {
                    this.setState({
                        status: ImportStatus.SENDING,
                        waybills: {
                            ...result
                        }
                    });
                } else {
                    history && history.push(`/import/${this.state.clientName}/${this.state.formName}/tasks/${result}`);
                }
            } catch (error) {
                this.setState({status: ImportStatus.FILES_SELECTED});
            }
        });
    }

    setPickupTypes = (id, value, forAll) => {
        this.setState(prevState => ({
            ...prevState,
            waybills: {
                ...prevState.waybills,
                send: prevState.waybills.send.map(item => forAll
                    ? item.isHub ? {...item, pickupType: value} : item
                    : item.id === id ? {...item, pickupType: value} : item)
            }
        }))
    };

    send = async () => {
        return await importWaybills(this.state.waybills.send);
    };

    registerWaybill = async () => {
        this.setState({sendProgress: true});
        const resultSend = await this.send();

        this.setState({resultMessages: [...(resultSend.map(x=>x.message))], sendProgress: false});

        if (resultSend.filter(x=>!x.isSuccess).length > 0) {
            this.setState({status: ImportStatus.SENDING_ERROR})
        } else {
            this.setState({status: ImportStatus.SENDING_SUCCESS})
        }

    };

    render() {
        const {status, acceptedFiles, errors, waybills = {}, resultMessages = [], sendProgress, openAll, modalVersion} = this.state;
        const {newTableFormat = true} = this.props;

        const sendBtnName = `Отправить заявк${[...(waybills.send || [])].length > 1 ? 'и' : 'у'}`;

        const statusInitial = (
            <div>
                {/* <div>
                    <a className="ui positive button"
                        href="/files/Заявка.xlsm"
                        target="_blank">
                        <i aria-hidden="true" className="download icon"></i>Скачать пример файла для импорта заявок</a>
                </div> */}
                <Dropzone
                    onDrop={this.onDrop.bind(this)}
                    multiple={false}
                    accept={EXCEL_FORMATS}
                    className='dropzone'
                ><T>Перетащите файл с заявками или кликните на это поле</T></Dropzone>
            </div>
        );

        const statusFilesSelected = <div><p>{acceptedFiles && acceptedFiles.length && acceptedFiles[0].name}</p></div>;
        const statusLoading = <p><T>Загрузка файлов...</T></p>;
        const statusError = <p><T>Ошибка импорта</T>: {errors}</p>;
        const processingNegative = (
            <React.Fragment>
                <Header>Ошибка регистрации заявки <Icon name="frown outline" /></Header>
                {resultMessages.map(text => <p>{text}</p>)}
            </React.Fragment>
        );

        const processingPositive = (
            <React.Fragment>
                <Header>Заявка(и) успешно приняты в работу<Icon name="smile outline" /></Header>
                {resultMessages.map(text => <p>{text}</p>)}
            </React.Fragment>
        );

        const allPickupTypes = [...new Set((waybills.send || []).filter(sr => sr.isHub).map(w => w.pickupType))];
        const allValue = allPickupTypes.length === 1 && allPickupTypes[0];
        const allIsNoHub = (waybills.send || []).every(sr => !sr.isHub);

        const statusSending = <Form>
            <Message
                info
                icon="info"
                content={<span>Если вы готовы отправить импортированные заявки в FM,
                    то нажмите на кнопку <b>{sendBtnName}</b>, если нет, то нажмите <b>Отмена</b></span>}
            />
            {(!allIsNoHub || !newTableFormat) && <Form.Field>
                <b>Как Вы планируете доставить груз на кросс-док ФМ?</b>
            </Form.Field>}

            {!allIsNoHub && <>
                    <Grid verticalAlign="middle" divided="vertically">
                        <GridRow className="p-b-0">
                            <GridColumn width={8}>Выбрать способ доставки для всех заявок</GridColumn>
                            <GridColumn width={8}>
                                <Form.Select
                                    disabled={openAll}
                                    fluid
                                    options={PickupTypeOptions}
                                    placeholder="Способ доставки груза"
                                    value={allValue}
                                    onChange={(e, {value}) => this.setPickupTypes(null, value, true)}
                                />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                    <Grid verticalAlign="middle" divided='vertically'>
                        <div
                            className='m-b-10'
                            onClick={() => !allIsNoHub && this.setState({openAll: !openAll})}
                        >
                            Выбрать способ доставки для каждой заявки отдельно
                            <Icon size='large' name={openAll ? 'angle down' : 'angle right'}/>
                        </div>
                    </Grid>
                </>
            }
            {newTableFormat && <Tables
                open={openAll}
                allIsNoHub={allIsNoHub}
                rows={[...(waybills.send || [])]}
                setValue={this.setPickupTypes}
            />}
            {((openAll || allIsNoHub) && !newTableFormat) && <div className="m-t-15 scrollable">
                <Divider/>
                <Grid verticalAlign="middle" divided="vertically">
                    <GridRow className="p-b-0">
                        <GridColumn width={!allIsNoHub ? 2 : 4}><T>Заявка</T></GridColumn>
                        <GridColumn width={!allIsNoHub ? 3 : 5}><T>Грузополучатель</T></GridColumn>
                        <GridColumn width={!allIsNoHub ? 4 : 7}><T>Адрес</T></GridColumn>
                        {!allIsNoHub && <GridColumn width={7}><T>Способ доставки</T></GridColumn>}
                    </GridRow>
                    {[...(waybills.send || [])].map(r => <GridRow className="p-b-0 p-t-0">
                            <GridColumn width={!allIsNoHub ? 2 : 4}>{r.id}</GridColumn>
                            <GridColumn width={!allIsNoHub ? 3 : 5}>{r.consignee}</GridColumn>
                            <GridColumn width={!allIsNoHub ? 4 : 7}>{r.address && r.address.toString()}</GridColumn>
                            {!allIsNoHub && <GridColumn width={7}>
                                {r.isHub && <Form.Select fluid
                                                         options={PickupTypeOptions}
                                                         placeholder="Способ доставки груза"
                                                         value={r.pickupType}
                                                         onChange={(e, {value}) => this.setPickupTypes(r.id, value)}
                                />}
                            </GridColumn>}
                    </GridRow>)}
                </Grid>
            </div>}
        </Form>;

        const handleItemClick = async (index) => {
            this.setState({
                activeIndex: index
            });
        };

        const setStateValue = (name, value) => {
            const data = {
                [name]: value
            };

            if (name === 'clientName') {
                data.formName = null;
            }

            this.setState(data);
        };

        const newFormat = () => {
            const {formName} = this.props;

            return <Modal.Content className="tabs-menu__content">
                {
                    (!formName && (!this.isOneClient() || this.state.fmid)) && <ImportFormSelect
                        form={this.state}
                        onChange={setStateValue}
                        isActive
                        fmid={this.state.fmid}
                        status={ImportFormStatusEnum.DONE}
                    />
                }

                <Modal.Description>
                    {status === ImportStatus.START && statusInitial}
                    {status === ImportStatus.FILES_SELECTED && statusFilesSelected}
                    {status === ImportStatus.IMPORTING && statusLoading}
                    {status === ImportStatus.ERROR && statusError}
                    {status === ImportStatus.SENDING && statusSending}
                    {status === ImportStatus.SENDING_ERROR && processingNegative}
                    {status === ImportStatus.SENDING_SUCCESS && processingPositive}
                </Modal.Description>
            </Modal.Content>;
        };

        const oldFormat = () => {
            return <Modal.Content>
                <Modal.Description>
                    {status === ImportStatus.START && statusInitial}
                    {status === ImportStatus.FILES_SELECTED && statusFilesSelected}
                    {status === ImportStatus.IMPORTING && statusLoading}
                    {status === ImportStatus.ERROR && statusError}
                    {status === ImportStatus.SENDING && statusSending}
                    {status === ImportStatus.SENDING_ERROR && processingNegative}
                    {status === ImportStatus.SENDING_SUCCESS && processingPositive}
                </Modal.Description>
            </Modal.Content>;
        };

        const panes = [
            {
                menuItem: 'Старый формат',
                render: () => oldFormat()
            },
            {
                menuItem: 'Новый формат',
                render: () => newFormat()
            }
        ];

        const {children, formName, withoutTabs} = this.props;

        const onlyNew = formName || modalVersion === 1;
        const onlyOld = !onlyNew && (withoutTabs || modalVersion === 0);

        return (
            <Modal trigger={children}
                   dimmer="inverted"
                   size={(newTableFormat && status === ImportStatus.SENDING) ? "large" : "small"}
                   closeIcon
                   open={this.state.open}
                   onOpen={this.handleOpen}
                   onClose={this.handleClose}
            >
                <Modal.Header><T>Импорт заявок из файла</T></Modal.Header>
                {
                    onlyOld && oldFormat()
                }
                {
                    onlyNew && newFormat()
                }
                {
                    (!onlyNew && !onlyOld) && <Tab
                        activeIndex={this.state.activeIndex}
                        className="tabs-menu"
                        menu={{attached: false, tabular: false}}
                        panes={panes}
                        onTabChange={(e, {activeIndex}) => {
                            handleItemClick(activeIndex);
                        }}
                    />
                }
                <Modal.Actions>
                    {
                        status !== ImportStatus.SENDING_SUCCESS && status !== ImportStatus.SENDING_ERROR
                        && <Button content={'Отмена'.t} onClick={this.handleClose}/>
                    }
                    {
                        status === ImportStatus.SENDING
                            ? <Button
                                primary
                                disabled={!(waybills.send || []).filter(sr => sr.isHub).every(sr => sr.pickupType > 0)}
                                content={sendBtnName}
                                loading={sendProgress}
                                onClick={this.registerWaybill}
                            />
                            : status === ImportStatus.SENDING_SUCCESS || status === ImportStatus.SENDING_ERROR ?
                            <Button onClick={this.handleClose}>Закрыть</Button>
                            : <Button loading={status === ImportStatus.IMPORTING}
                                      icon='check'
                                      disabled={status !== ImportStatus.FILES_SELECTED || !(this.state.activeIndex === 0 || !!this.state.formName)}
                                      positive
                                      content={this.state.activeIndex === 0 ? 'Импортировать заявки' : 'Перейти к обработке'}
                                      onClick={this.import.bind(this)}/>
                    }
                </Modal.Actions>
            </Modal>
        );
    }
};
